<template>
    <div class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>  
        <div class="page-desc">
           耗材调拨：可将部分耗材调拨到指定分部仓库进行正常使用
         </div>   
		<TableVue ref="c_table"></TableVue>
	</div>   
</template>
<script>
import TableVue from '../Common/ConsumableTable.vue'
export default {
    components:{
		TableVue,
    },
	 inject:['reload'],
   data() {
      return {
      }
   },
   created(){
		this.$nextTick(()=>{
			this.$refs.c_table.rUrl = {
				index:'/CseAllot/index',
				add:'/CseAllot/add',
				edit:'/CseAllot/edit',
				del:'/CseAllot/del',
				export:'/CseAllot/export'
			} 
			this.$refs.c_table.cardName =  '耗材调拨单'
			this.$refs.c_table.storageType = 4
			this.$refs.c_table.getData();
		})
   },
   mounted(){
		
   },
   methods:{
		
   }

}
</script>

</style>